import { faAngleDown, faGear, faHashtag, faQuestion, faRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import type { i18n as i18nType } from 'i18next';
import type { DropdownProps } from 'semantic-ui-react';

import { logout } from './actions/authActions';
import EnvSettings from './api/EnvSettings';
import Dropdown from './Components/generic/Dropdown/Dropdown';
import UserAvatar from './Components/User/UserAvatar';
import Internationalization from './i18n';
import { setHtmlLangAttribute } from './Utilities/helper';

import type { State } from './types/initialState';

import './ProfileDropdown.css';

const ProfileDropdown = () => {
  const { t, i18n } = useTranslation();
  const userData = useSelector((state: State) => state.userData);
  const dispatch = useDispatch();
  const manualLogout = useCallback(() => {
    dispatch(logout({ type: 'manual' }));
  }, [dispatch]);

  let defaultLang = 'fi';
  if (userData && userData.userPreferences) {
    defaultLang = userData.userPreferences.defaultLanguage;
  }

  const [lang, setLang] = useState(i18n.language || defaultLang);

  useEffect(() => {
    getI18n().changeLanguage(lang);
    setHtmlLangAttribute(lang);
  }, []);

  const changeLanguage = (i18nInstance: i18nType, data: DropdownProps) => {
    if (Array.isArray(data.value) || !data.value || typeof data.value !== 'string') {
      return;
    }

    Internationalization.changeLanguage(i18nInstance, data.value);
    setLang(data.value);
  };

  const userName = `${userData.profile.firstName} ${userData.profile.lastName}`;
  const version = import.meta.env.VITE_CONTAINER_IMAGE;

  return (
    <Dropdown
      className="user"
      value={userName}
      selection
      style={{ zIndex: 10000 }}
      icon={<FontAwesomeIcon icon={faAngleDown} />}
      trigger={
        <div className="divider text">
          <UserAvatar
            UID={userData.UID}
            className="navBarProfileAvatar"
            round
            size="40"
            imgClassName="ProfileDropdown-Image"
          />
          <div className="ProfileDropdown-Name">
            {userName}
            <span className="ProfileDropdown-Email">{userData.profile.email}</span>
          </div>
        </div>
      }
      options={[
        {
          value: 'lang',
          content: (
            <Dropdown
              icon={<FontAwesomeIcon icon={faAngleDown} style={{ marginLeft: '20px' }} />}
              style={{ minWidth: '150px' }}
              selectOnBlur={false}
              value={lang}
              direction="left"
              onChange={(e, data) => changeLanguage(i18n, data)}
              options={Internationalization.getLanguages()}
            />
          )
        },
        {
          value: 'version',
          disabled: true,
          icon: <FontAwesomeIcon icon={faHashtag} style={{ marginRight: '8px' }} />,
          content: `${t('VERSION')} ${version ?? ''}`
        },
        {
          value: 'instructions',
          href: EnvSettings.getSettings().GUIDE_URL,
          target: '_blank',
          icon: <FontAwesomeIcon icon={faQuestion} style={{ marginRight: '8px' }} />,
          content: t('INSTRUCTIONS')
        },
        {
          value: 'settings',
          icon: <FontAwesomeIcon icon={faGear} style={{ marginRight: '8px' }} />,
          content: (
            <Link style={{ color: 'inherit' }} to="/settings">
              {t('PREFERENCES_EDIT_SETTINGS')}
            </Link>
          )
        },
        {
          value: 'logout',
          onClick: manualLogout,
          icon: <FontAwesomeIcon icon={faRightFromBracket} style={{ marginRight: '8px' }} />,
          content: t('MAIN_TOPBAR_LOGOUT')
        }
      ]}
    />
  );
};

export default ProfileDropdown;
