import React from 'react';
import { useDispatch } from 'react-redux';

import type { FC } from 'react';

import MainTab from './MainTab';
import TicketListTab from './TicketListTab';
import { closeInfopagelistTab, closeTicketlistTab } from 'src/actions/ticketListTabActionsRTK';
import Section from 'src/Components/generic/Section/Section';
import { setTicketListActiveTab } from 'src/reducers/ticketListActiveTabsReducer';
import { useAppSelector } from 'src/store';
import { StaticTabs } from 'src/types/TicketList';

const MainTabs: FC = () => {
  const dispatch = useDispatch();

  const isInfopage = location?.pathname.startsWith('/infopage');

  const tabs = useAppSelector((state) =>
    isInfopage
      ? [...state.infoPageListTabs.values()].filter((tab) => tab.id !== StaticTabs.MAIN_VIEW)
      : Object.values(state.ticketListTabs).filter((tab) => tab.id !== StaticTabs.MAIN_VIEW)
  );

  const activeTabId = useAppSelector((state) => state.ticketListActiveTabs.main) ?? StaticTabs.MAIN_VIEW;

  const handleSelectTab = React.useCallback(
    (id: string) => {
      dispatch(setTicketListActiveTab(id));
    },
    [dispatch]
  );

  const handleCloseTab = React.useCallback(
    (id: string) => {
      dispatch(setTicketListActiveTab(StaticTabs.MAIN_VIEW));
      isInfopage ? dispatch(closeInfopagelistTab(id)) : dispatch(closeTicketlistTab(id));
    },
    [dispatch, isInfopage]
  );

  return (
    <Section direction="row" gap={8} padding="0 20px" margin="0 0 10px 0">
      <MainTab />
      {tabs.length > 0 &&
        tabs.reverse().map((tab) => {
          return (
            <TicketListTab
              tab={tab}
              activeTabId={activeTabId}
              onSelectTab={(id) => handleSelectTab(id)}
              onCloseTab={(id) => handleCloseTab(id)}
            />
          );
        })}
    </Section>
  );
};

export default React.memo(MainTabs);
