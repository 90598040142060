import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import type { FC } from 'react';

import { useDiscussionContext } from '../Comments/DiscussionContext';
import styles from './CommentTextEncapsulated.css?inline';

type CommentTextEncapsulatedProps = {
  comment?: string | null;
  isCommentExpanded?: boolean;

  setCommentTextHeight?: (height: number) => void;
};

export const EXPAND_COMMENT_HEIGHT = 150;
export const EXPAND_COMMENT_LENGTH = 500;

const styleElement = `<style>${styles}</style>`;
const REPLY_DELIMITERS = [
  '----------------------------------------------------',
  '________________________________',
  '-----Original Message-----',
  '---- Forwarded message ----'
];

function splitOnFirstFoundDelimiter(text: string, delimiters: string[]) {
  for (const delimiter of delimiters) {
    if (text.includes(delimiter)) {
      return {
        foundDelimiter: delimiter,
        splitted: text.split(delimiter)
      };
    }
  }
  return { foundDelimiter: undefined, splitted: [text] };
}

const CommentTextEncapsulated: FC<CommentTextEncapsulatedProps> = ({
  comment,
  isCommentExpanded,
  setCommentTextHeight
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [isExpandedReplySection, setExpandedReplySection] = useState(false);
  const { isEnableReplyExpanding } = useDiscussionContext();
  const onExpandReplyContent = () => {
    setExpandedReplySection((prevValue) => !prevValue);
  };

  const text = comment ?? '';
  const { foundDelimiter, splitted } = useMemo(() => {
    return splitOnFirstFoundDelimiter(text, REPLY_DELIMITERS);
  }, [comment]);
  const hasReplyContent = splitted.length > 1 && foundDelimiter;

  const getDisplayedComment = useCallback(() => {
    if (hasReplyContent) {
      if (!isCommentExpanded || !isEnableReplyExpanding) {
        return splitted.join(foundDelimiter);
      }

      if (isCommentExpanded && isExpandedReplySection) {
        const afterDelimiter = splitted.slice(1).join(foundDelimiter);
        return `
          ${splitted[0]}
          <div class="replyContentPlaceholder">...</div>
          ${afterDelimiter}
        `;
      }

      return `
        ${splitted[0]}
        <div class="replyContentPlaceholder">...</div>
      `;
    } else {
      return isCommentExpanded ? text : text.slice(0, EXPAND_COMMENT_LENGTH);
    }
  }, [isExpandedReplySection, isEnableReplyExpanding, comment, isCommentExpanded]);

  useLayoutEffect(() => {
    let removeClickListener: (() => void) | undefined;

    if (divRef.current && comment) {
      let { shadowRoot } = divRef.current;
      if (!shadowRoot) {
        shadowRoot = divRef.current.attachShadow({ mode: 'open' });
      }

      if (shadowRoot) {
        shadowRoot.innerHTML = `
            ${styleElement}
            <div class="commentTextEncapsulatedContainer">${getDisplayedComment()}</div>
          `;
      }

      if (isCommentExpanded && hasReplyContent && shadowRoot) {
        const placeholderEl = shadowRoot.querySelector('.replyContentPlaceholder');
        if (placeholderEl) {
          const handlePlaceholderClick = () => {
            onExpandReplyContent();
          };

          placeholderEl.addEventListener('click', handlePlaceholderClick);
          removeClickListener = () => {
            placeholderEl.removeEventListener('click', handlePlaceholderClick);
          };
        }
      }

      if (typeof setCommentTextHeight === 'function' && divRef.current) {
        setCommentTextHeight(divRef.current.clientHeight);
      }
    }

    return () => {
      if (removeClickListener) {
        removeClickListener();
      }
    };
  }, [isExpandedReplySection, isEnableReplyExpanding, comment, setCommentTextHeight, isCommentExpanded]);

  return <div ref={divRef}></div>;
};

export default React.memo(CommentTextEncapsulated);
