import moment from 'moment/moment';

import type { Tag, UserWithProfile } from '@eeedo/types';
import type { TFunction } from 'i18next';

import { actionTypes } from 'src/Components/Management/Rules/RuleConfigurationData';
import { userToNumericalId } from 'src/Utilities/users';

import type { Attachment, CommentMetadata } from 'src/types/Ticket';

export const translateQuarantine = (
  content: string,
  quarantine: boolean,
  attachments: Attachment[],
  t: TFunction
): string => {
  const attachmentId = content.substring(0, content.length - 1);
  const attachment = attachments.find((attachment: Attachment) => attachment.id.substring(3) === attachmentId);

  return quarantine
    ? `${t('AUTOMATIC_COMMENT_SET_QUARANTINE')} (${attachment?.fileName})`
    : `${t('AUTOMATIC_COMMENT_RELEASE_QUARANTINE')} (${attachment?.fileName})`;
};

export const translateComment = (
  userString: string,
  action:
    | 'START'
    | 'STOP'
    | 'ADDED_ATTACHMENT'
    | 'REPLACE'
    | 'DELEGATED_TASK_TO'
    | 'DELEGATION_REMOVED_FROM'
    | 'NEW_TASK_STATUS_IS'
    | 'DETACHED_CUSTOMER'
    | 'USER_CALLED_TO_NUMBER'
    | 'ATTACHED_CUSTOMER'
    | 'ATTACHED_PREVIOUSLY_SERVED_CUSTOMER'
    | 'TICKET_READ_BY_CUSTOMER'
    | 'EDITED_CONTENT'
    | 'APPLIED_RULE'
    | 'APPLIED_ALWAYSRUN_RULE'
    | 'VERIFY'
    | 'VERIFY_OPEN'
    | 'VERIFIED_FIELDS'
    | 'ORIGINAL_CONTACT_CHANGED'
    | 'TAG_ADDED'
    | 'TAG_REMOVED'
    | 'BOT_RESPONSE_COMMENT_ERROR'
    | 'BOT_RESPONSE_PATCH_ERROR'
    | 'BOT_RESPONSE_FAILED_BECAUSE_CHAT_IS_CLOSED_COMMENT_ERROR'
    | 'EIDENTIFICATION_VERIFIED'
    | 'UNANSWERED_CALL_FROM'
    | 'WEBHOOK_SUCCESS'
    | 'WEBHOOK_FAIL'
    | 'CALL_RESPONSE_TIME'
    | 'WEBHOOK_FAIL_NEXT_RETRY'
    | 'WEBHOOK_FAIL_LAST_RETRY'
    | 'RULES_ACTION_APPLIED',
  t: TFunction,
  props?: {
    usersData?: UserWithProfile[];
    tags?: Tag[];
    metaData?: CommentMetadata | null;
  }
): string | undefined => {
  userString = userString.substring(0, userString.length - 1);
  if (typeof userString === 'undefined' || userString === 'undefined') {
    return t('UNKNOWN_USER_PERFORMED_ACTION');
  }
  const users = userString.split(',');
  switch (action) {
    case 'START':
    case 'STOP': {
      const userData = props?.usersData?.find((user) => user.UID.substring(3) === users[0]);
      if (!userData) {
        return t('ERROR_DATA_WAS_NOT_FOUND');
      }
      const userName = `${userData.profile.firstName || ''} ${userData.profile.lastName}`;
      return t('AUTOMATIC_COMMENT_WORKSTATUS_' + action, { userName });
    }
    case 'REPLACE': {
      const previousUserData = props?.usersData?.find((user) => user.UID.substring(3) === users[0]);
      const nextUserData = props?.usersData?.find((user) => user.UID.substring(3) === users[1]);

      if (!previousUserData || !nextUserData) {
        return t('ERROR_DATA_WAS_NOT_FOUND');
      }

      const previousUserName = `${previousUserData.profile.firstName || ''} ${previousUserData.profile.lastName}`;
      const nextUserName = `${nextUserData.profile.firstName || ''} ${nextUserData.profile.lastName}`;

      return t('AUTOMATIC_COMMENT_WORKSTATUS_REPLACE', {
        previousUserName,
        nextUserName
      });
    }
    case 'CALL_RESPONSE_TIME': {
      const momentDuration = moment.duration({ milliseconds: Number(userString) });
      return t('AUTOMATIC_COMMENT_CALL_RESPONSE_TIME', {
        time: momentDuration.isValid() ? momentDuration.humanize({ ss: 0 }) : userString
      });
    }
    case 'DETACHED_CUSTOMER':
      return t('AUTOMATIC_COMMENT_DETACHED_CUSTOMER');
    case 'NEW_TASK_STATUS_IS': {
      const sentence = t('AUTOMATIC_COMMENT_NEW_TASK_STATUS_IS');
      const translatedStatus = t('CASE_STATUS_' + userString.trim().substring(1).toUpperCase());
      return `${sentence} ${translatedStatus}`;
    }
    case 'USER_CALLED_TO_NUMBER':
      return t('USER_CALLED_TO_NUMBER');
    case 'ADDED_ATTACHMENT':
      return t('AUTOMATIC_COMMENT_ADDED_ATTACHMENT');
    case 'DELEGATED_TASK_TO':
      return t('AUTOMATIC_COMMENT_DELEGATED_TASK_TO');
    case 'DELEGATION_REMOVED_FROM':
      return t('AUTOMATIC_COMMENT_DELEGATION_REMOVED_FROM');
    case 'ATTACHED_CUSTOMER':
      return t('AUTOMATIC_COMMENT_ATTACHED_CUSTOMER');
    case 'ATTACHED_PREVIOUSLY_SERVED_CUSTOMER':
      return t('AUTOMATIC_COMMENT_ATTACHED_PREVIOUSLY_SERVED_CUSTOMER');
    case 'TICKET_READ_BY_CUSTOMER':
      return t('CUSTOMER_PORTAL_TICKET_READ_BY_CUSTOMER');
    case 'EDITED_CONTENT':
      return t('AUTOMATIC_COMMENT_EDITED_CONTENT');
    case 'BOT_RESPONSE_COMMENT_ERROR':
      return t('BOT_RESPONSE_COMMENT_ERROR');
    case 'BOT_RESPONSE_FAILED_BECAUSE_CHAT_IS_CLOSED_COMMENT_ERROR':
      return t('BOT_RESPONSE_FAILED_BECAUSE_CHAT_IS_CLOSED_COMMENT_ERROR');
    case 'BOT_RESPONSE_PATCH_ERROR':
      return `${t('BOT_RESPONSE_PATCH_ERROR')}: ${userString}`;
    case 'APPLIED_RULE':
      return `${t('AUTOMATIC_COMMENT_APPLIED_RULE')}: ${userString}`;
    case 'APPLIED_ALWAYSRUN_RULE':
      return `${t('AUTOMATIC_COMMENT_APPLIED_ALWAYSRUN_RULE')}: ${userString}`;
    case 'VERIFY': {
      const matches = userString.match(/(\d+)] (.*)/);
      const id = Number(matches?.[1]);
      const fields = matches?.[2];
      const user = props?.usersData?.find((user) => userToNumericalId(user.UID) === id);
      return `${t('AUTOMATIC_COMMENT_VERIFY', {
        userName: (user?.profile.firstName || '') + (user?.profile.lastName || ''),
        fields: fields
      })}`;
    }
    case 'VERIFY_OPEN': {
      const matches = userString.match(/(\d+)]/);
      const id = Number(matches?.[1]);
      const user = props?.usersData?.find((user) => userToNumericalId(user.UID) === id);
      return `${t('AUTOMATIC_COMMENT_VERIFY_OPEN', {
        userName: (user?.profile.firstName || '') + (user?.profile.lastName || '')
      })}`;
    }
    case 'VERIFIED_FIELDS': {
      const fields = userString;
      return `${t('AUTOMATIC_COMMENT_VERIFIED_FIELDS', { fields: fields.split(',').join(', ') })}`;
    }
    case 'ORIGINAL_CONTACT_CHANGED': {
      return `${t('AUTOMATIC_COMMENT_ORIGINAL_CONTACT_CHANGED')}: ${userString}`;
    }
    case 'TAG_ADDED': {
      const [tagID, UID] = userString.split(',');
      const user = props?.usersData?.find((user) => user.UID.substring(3) === UID);
      const tag = props?.tags?.find((tag) => tag.id === tagID);

      return t('AUTOMATIC_COMMENT_TAG_ADDED', {
        tagName: tag?.name,
        firstName: user?.profile.firstName,
        lastName: user?.profile.lastName
      });
    }
    case 'TAG_REMOVED': {
      const [tagID, UID] = userString.split(',');
      const user = props?.usersData?.find((user) => user.UID.substring(3) === UID);
      const tag = props?.tags?.find((tag) => tag.id === tagID);

      return t('AUTOMATIC_COMMENT_TAG_REMOVED', {
        tagName: tag?.name,
        firstName: user?.profile.firstName,
        lastName: user?.profile.lastName
      });
    }
    case 'EIDENTIFICATION_VERIFIED': {
      return t('AUTOMATIC_COMMENT_EIDENTIFICATION_VERIFIED', { hash: userString });
    }
    case 'UNANSWERED_CALL_FROM': {
      const [phoneNumber, duration = '', acdName = ''] = userString.split(',');
      return t('AUTOMATIC_COMMENT_UNANSWERED_CALL_FROM', { phoneNumber, duration, acdName });
    }
    case 'WEBHOOK_SUCCESS': {
      const { name, rpaRuleId, responseStatus } = props?.metaData as Record<string, unknown>;
      return t('AUTOMATIC_COMMENT_WEBHOOK_SUCCESS', { name, rpaRuleId, responseStatus });
    }
    case 'WEBHOOK_FAIL': {
      const { name, rpaRuleId, responseStatus } = props?.metaData as Record<string, unknown>;
      return t('AUTOMATIC_COMMENT_WEBHOOK_FAIL', { name, rpaRuleId, responseStatus });
    }
    case 'WEBHOOK_FAIL_NEXT_RETRY': {
      const { name, rpaRuleId, nextInvocationCount, responseStatus } = props?.metaData as Record<string, unknown>;
      return t('AUTOMATIC_COMMENT_WEBHOOK_FAIL_NEXT_RETRY', {
        name,
        rpaRuleId,
        nextInvocationCount,
        responseStatus
      });
    }
    case 'WEBHOOK_FAIL_LAST_RETRY': {
      const { name, rpaRuleId, invocationCount, responseStatus } = props?.metaData as Record<string, unknown>;
      return t('AUTOMATIC_COMMENT_WEBHOOK_FAIL_LAST_RETRY', {
        name,
        rpaRuleId,
        invocationCount,
        responseStatus
      });
    }
    case 'RULES_ACTION_APPLIED': {
      const { ruleId, ruleName, actionType: actionTypeValue } = props?.metaData as Record<string, unknown>;
      const actionType = t(actionTypes.find((element) => element.value === actionTypeValue)!.text);
      return t('AUTOMATIC_COMMENT_RULES_ACTION_APPLIED', {
        ruleId,
        ruleName,
        actionType
      });
    }
    default:
      return;
  }
};
